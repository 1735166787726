// @layout-header-background: '#030852',
// @primary-color: '#1DA57A',
@disabled-color: fade(#000, 75%); // default fade(#000, 25%)
@btn-disable-color: fade(#000, 25%); // default @disabled-color

// @font-size-base: 13px;
// @font-size-lg: @font-size-base + 2px;
// @font-size-sm: @font-size-base - 2px;
// @btn-font-size-lg: @font-size-lg;
// @btn-font-size-sm: @font-size-sm;
// @btn-text-hover-bg: rgba(0, 0, 0, 0.1);

// @font-family: 
//   -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 
//   'Microsoft YaHei', Roboto, 'Helvetica Neue', Helvetica, Arial, 'Noto Sans', sans-serif, 
//   'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

@font-family: Avenir, "Open Sans", Roboto,
  Menlo, Monaco, "Lucida Console", "Liberation Mono", "DejaVu Sans Mono",
  "Bitstream Vera Sans Mono", "Courier New", monospace,
  -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url("./fonts/AvenirLTStd-Book.otf") format("opentype");
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url("./fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto-Regular.ttf") format("truetype");
}